@tailwind base;
@tailwind components;
@tailwind utilities;

/* Default Font Family */
@font-face {
  font-family: "ClarityCity-Bold";
  src: url(./assets/font/ClarityCity-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "ClarityCity-ExtraBold";
  src: url(./assets/font/ClarityCity-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "ClarityCity-Light";
  src: url(./assets/font/ClarityCity-Light.ttf) format("truetype");
}
@font-face {
  font-family: "ClarityCity-Regular";
  src: url(./assets/font/ClarityCity-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "ClarityCity-SemiBold";
  src: url(./assets/font/ClarityCity-SemiBold.ttf) format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ClarityCity-Bold", sans-serif;
}

p {
  font-family: "Inter", sans-serif;
}

button {
  font-family: "ClarityCity-Semibold", sans-serif;
}

/*
.main-container {
  background-image: linear-gradient(90deg, #fff8e5 50%, #fff 50%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 85.1%;
}
*/

.myShadow {
  box-shadow: -9.39054px 9.39054px 28.1716px 10.3296px rgba(74, 34, 179, 0.05);
}
